<template>
  <v-card :loading="loading">
    <v-card-title>
      Please enter a date and time of occurance
    </v-card-title>
    <v-divider/>
    <v-card-text class="px-0 py-0">
      <v-tabs fixed-tabs v-model="activeTab">
        <v-tab v-for="tab in tabs" :key="tab.key" :disabled="tab.disabled">
          <v-icon v-text="tab.icon" />
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item v-for="tab in tabs" :key="tab.key">
          <component
            v-bind:is="tab.component"
            v-model="form[tab.model]"
            @input="tab.cb"
            full-width
            format="24hr"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="$emit('close-dialog')">Cancel</v-btn>
      <v-btn text color="primary darken-1" :disabled="!form.date" @click="clickAccept">Accept</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment'

const DEFAULT_DATE = ''
const DEFAULT_TIME = '00:00:00'
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
const DEFAULT_TIME_FORMAT = 'HH:mm'

export default {
  name: 'pibot-datetime-picker-card',
  props: {
    datetime: {
      type: [Number],
      default: null
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    }
  },
  data: () => ({
    activeTab: null,
    form: {
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    },
    loading: false
  }),
  computed: {
    tabs () {
      return [
        {
          key: 'calendar',
          icon: 'event',
          component: 'v-date-picker',
          props: this.datePickerProps,
          cb: this.showTimePicker,
          model: 'date'
        },
        {
          key: 'timer',
          icon: 'access_time',
          component: 'v-time-picker',
          props: this.timePickerProps,
          cb: () => {},
          model: 'time',
          disabled: !!this.dateSelected
        }
      ]
    },
    selectedTimestamp () {
      if (!this.form.date) return 0
      let datetimeString = `${this.form.date} ${this.form.time}`
      if (this.form.time.length === 5) datetimeString += ':00'
      return moment(datetimeString, this.defaultDateTimeFormat).unix() * 1000
    },
    dateSelected () {
      return !this.form.date
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      if (!this.datetime) return

      const datetimeMoment = moment(this.datetime)
      this.form.date = datetimeMoment.format(DEFAULT_DATE_FORMAT)
      this.form.time = datetimeMoment.format(DEFAULT_TIME_FORMAT)
    },
    showTimePicker () {
      this.activeTab = 1
    },
    clickAccept () {
      this.loading = true
      this.$emit('escalate', this.selectedTimestamp)
    }
  }
}
</script>
